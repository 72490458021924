import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";

const setVisible = props => {
  if (!props.rendering.dataSource || props.rendering.dataSource === "") {
    return "hide";
  }
  return "show";
};

class FeatureFloorPlanFloorPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelData: [],
      enableMap: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement("script");
    const { Map } = this.props.fields || {};

    if (setVisible(this.props) === "show") {

      window.mappedin = {
        miKey: {
          id: "5b27ff074738e142bc3596a8",
          key: "cnLpUwpoMZNygC8AiDRB9K3xe5fISsE5CZ8SBhO0jUDRU1U6"
        },
        venue: Map.fields.Venue.value
      };
      script.src =
        "https://cdn.mappedin.com/web2/release/mappedin-web.js";
      script.type = "text/javascript";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  render() {
    const { Map } = this.props.fields || {};
    const {
      Title = null,
      SectionId = null
    } = this.props.fields || {};
    return (
      setVisible(this.props) === "show" && (
        <BaseComponent
          margin="standard"
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className="floorplan">
            <div className="container">
              <SectionDivider margin="24" />
              <div className="title">
                <Text field={Title} />
              </div>
              {this.props.fields &&
                this.props.fields.Map &&
                this.props.fields.Map.fields && (
                  <div className="map-wrapper" ref={this.setWrapperRef}>
                    <div id="mappedin-map" className="map-content">
                      <main
                        className="map"
                        data-key="externalId"
                        data-venue={Map.fields.Venue.value}
                        id="mappedin-app"
                      ></main>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureFloorPlanFloorPlan;
